import * as Yup from 'yup'

const FormSchema = Yup.object()
  .shape({
    firstName: Yup.string().required().label('First Name'),
    lastName: Yup.string().required().label('Last Name'),
    email: Yup.string().email().required().label('Email'),
    phone: Yup.string().label('Phone'),
    subject: Yup.string().required().label('Subject'),
    message: Yup.string().required().max(500).label('Message'),
  })
  .defined()

export default FormSchema
