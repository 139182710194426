import Image from 'components/Image'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const BlogSection: FC<Sanity.BlogSection> = ({
  heading,
  numberOfPosts,
  supportingText,
}) => {
  const {
    allSanityPost: { latestPosts },
  } = useStaticQuery(graphql`
    query LatestPostsQuery {
      allSanityPost(limit: 10, sort: { fields: publishedAt, order: DESC }) {
        latestPosts: nodes {
          ...SanityPostData
        }
      }
    }
  `)

  const posts = latestPosts.slice(0, numberOfPosts)
  return (
    <div className="relative bg-gray-200 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      {(heading || supportingText) && (
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
      )}
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          {heading && (
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              {heading}
            </h2>
          )}
          {supportingText && (
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              {supportingText}
            </p>
          )}
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post: Sanity.Post) => (
            <Link key={post.slug.current} to={`/blog/${post.slug.current}`}>
              <div className="flex flex-col h-full rounded-lg shadow-lg overflow-hidden">
                <div>
                  {post.featuredImage ? (
                    <Image
                      className="h-48 w-full object-cover"
                      image={post.featuredImage}
                    />
                  ) : (
                    <StaticImage
                      className="h-48 w-full object-cover"
                      src="../../../../assets/default-post-image.png"
                      alt="Peak Performance Chiropractic"
                    />
                  )}
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <div className="flex flex-row">
                      {post.tags?.map(tag => (
                        <p
                          key={tag}
                          className="text-sm font-medium uppercase text-teal-500 mr-2"
                        >
                          {tag}
                        </p>
                      ))}
                    </div>
                    <div className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900">
                        {post.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {post.excerpt}
                      </p>
                    </div>
                  </div>
                  <div className="flex1">
                    <div className="mt-6 flex items-center">
                      <div className="ml-3">
                        <div className="flex space-x-1 text-sm text-gray-500">
                          <p>{post.publishedAt}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BlogSection
