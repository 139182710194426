import type { FC } from 'react'
import React from 'react'

const BlockRenderer: FC = ({ children }) => {
  return (
    <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
      {children}
    </h2>
  )
}

export default BlockRenderer
