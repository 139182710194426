import Image from 'components/Image'
import type { FC } from 'react'
import React from 'react'
import Slider from 'react-slick'
import { Sanity } from 'types/sanity'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ImageCarouselSection: FC<Sanity.ImageCarouselSection> = ({ images }) => {
  return (
    <div className="h-auto relative max-w-3xl mx-auto py-4 px-4 sm:px-6 lg:px-4">
      <Slider
        arrows={false}
        autoplay={true}
        dots={true}
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
      >
        {images.map(image => (
          <Image key={image._key} image={image} height={400} width={600} />
        ))}
      </Slider>
    </div>
  )
}

export default ImageCarouselSection
