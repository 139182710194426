import Footer from './Footer'
import Header from './Header'
import HeroSection from './HeroSection'
import Sections from './Sections'
import SEO from './SEO'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

type Props = {
  description?: string
  sections: Sanity.PageSections
  title: string
}

const Page: FC<Props> = ({ description, sections, title }) => {
  const hasHeroSection = sections[0]._type === 'heroSection'
  return (
    <div className="bg-white">
      <SEO description={description} title={title} />
      {hasHeroSection ? (
        <HeroSection {...(sections[0] as Sanity.HeroSection)} />
      ) : (
        <Header />
      )}
      <main>
        <Sections sections={sections} />
      </main>
      <Footer />
    </div>
  )
}

export default Page
