import { DownloadIcon } from '@heroicons/react/outline'
import { graphql, useStaticQuery } from 'gatsby'
import type { FC } from 'react'
import React from 'react'

type Props = {
  className: string
  text: string
}

const DownloadPaperwork: FC<Props> = ({ className, text }) => {
  const {
    siteSettings: { paperwork },
  } = useStaticQuery(graphql`
    query NewPatientPaperwork {
      siteSettings: sanitySiteSettings {
        paperwork: companyPaperwork {
          asset {
            url
          }
        }
      }
    }
  `)
  return (
    <a href={`${paperwork.asset.url}?dl=`} className={className}>
      {text}
      <DownloadIcon className="ml-2 -mr-0.5 h-6 w-6" aria-hidden="true" />
    </a>
  )
}

export default DownloadPaperwork
