import Hours from 'components/Page/Footer/Hours'
import type { FC } from 'react'
import React from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Sanity } from 'types/sanity'

type Props = {
  companyAddress: Sanity.Block[]
  companyName: string
  position: number[]
}

const LeafletMap: FC<Props> = ({ companyAddress, companyName, position }) => {
  return (
    <div className="w-full h-96 lg:h-128">
      <MapContainer
        center={position}
        zoom={16}
        scrollWheelZoom={false}
        style={{ height: '100%', width: '100%' }}
      >
        <TileLayer
          // eslint-disable-next-line
          // @ts-ignore
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>
            <strong>{companyName}</strong>
            <br />
            <Hours blocks={companyAddress} />
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  )
}

export default LeafletMap
