import Image from 'components/Image'
import BlockContent from 'components/Post/BlockContent'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const TeamMembersSection: FC<Sanity.TeamMemberSection> = ({
  cta,
  heading,
  teamMember,
}) => {
  const bio = teamMember._rawBio
  const half = Math.ceil(bio.length / 2)

  const bioFirstHalf = bio.slice(0, half)
  const bioSecondHalf = bio.slice(-half)
  return (
    <>
      <div className="bg-white pt-16 lg:py-24">
        <div className="pb-16 bg-teal-600 lg:pb-0 lg:z-10 lg:relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
            <div className="relative lg:-my-8">
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"
              />
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                  <Image
                    className="object-contain lg:h-full lg:w-full rounded-xl border-2 border-teal-300 relative"
                    image={teamMember.photo}
                  />
                </div>
              </div>
            </div>
            <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                <div className="mt-8">
                  <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                    {teamMember.name}
                  </h2>
                  <p className="text-xl font-medium text-teal-100">
                    {teamMember.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 xl:py-18 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="relative z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {heading}
              </h3>
            </div>
          </div>
          <div className="relative">
            <svg
              className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
              />
            </svg>
            <svg
              className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
              />
            </svg>
            <div className="relative md:bg-white md:p-6">
              <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                <div className="prose prose-teal prose-lg text-gray-500 lg:max-w-none">
                  <BlockContent blocks={bioFirstHalf} />
                </div>
                <div className="mt-6 prose prose-teal prose-lg text-gray-500 lg:mt-0">
                  {bio.length > 1 && <BlockContent blocks={bioSecondHalf} />}
                </div>
              </div>
              <div className="mt-8 inline-flex rounded-md shadow">
                <a
                  href={`/${cta.page.slug.current}`}
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700"
                >
                  {cta.text}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamMembersSection
