import LeafletMap from './LeafletMap'
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { graphql, useStaticQuery } from 'gatsby'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const MapSection: FC<Sanity.MapSection> = ({ heading, supportingText }) => {
  const { siteSettings } = useStaticQuery(graphql`
    query MapInfo {
      siteSettings: sanitySiteSettings {
        _rawCompanyAddress
        companyEmail
        companyLocation {
          lat
          lng
        }
        companyPhone
        companyName
      }
    }
  `)
  return (
    <div className="flex flex-col-reverse lg:flex-row justify-center max-w-7xl mx-auto py-8 px-4 sm:py-24 sm:px-6 lg:px-4">
      <div className="mt-20 md:mt-0 flex flex-col justify-center p-8">
        <h2 className="text-3xl font-extrabold text-gray-900">{heading}</h2>
        <p className="text-base text-gray-500 mb-8">{supportingText}</p>
        <a href={`mailto:${siteSettings.companyEmail}`} className="flex">
          <div className="flex-shrink-0">
            <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>
          <div className="ml-3 text-base text-gray-500">
            <p>{siteSettings.companyEmail}</p>
          </div>
        </a>
        <a href={`tel:${siteSettings.companyPhone}`} className="flex mt-6">
          <div className="flex-shrink-0">
            <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>
          <div className="ml-3 text-base text-gray-500">
            <p>{siteSettings.companyPhone}</p>
          </div>
        </a>
      </div>
      {typeof window !== 'undefined' && (
        <LeafletMap
          companyAddress={siteSettings._rawCompanyAddress}
          companyName={siteSettings.companyName}
          position={Object.values(siteSettings.companyLocation)}
        />
      )}
    </div>
  )
}

export default MapSection
