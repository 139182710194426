import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const ListSection: FC<Sanity.ListSection> = ({ heading, list }) => {
  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-12 px-4 divide-y divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl text-center font-extrabold text-gray-900">
          {heading}
        </h2>
        <div className="mt-8">
          <dl className="divide-y divide-gray-200">
            {list.map((listItem, index) => (
              <div
                key={index}
                className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8"
              >
                <dt className="text-base font-medium text-gray-900 md:col-span-5">
                  {listItem.heading}
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base text-gray-500">
                    {listItem.supportingText}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default ListSection
