import Image from 'components/Image'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const TeamMembersSection: FC<Sanity.TeamMembersSection> = ({
  heading,
  supportingText,
  teamMembers,
}) => {
  const numberOfTeamMembers = teamMembers.length
  const numberOfColumns = numberOfTeamMembers > 3 ? 3 : numberOfTeamMembers
  const gridColumns = `lg:grid-cols-${numberOfColumns}`

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
      <div className="space-y-12">
        <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
          <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
            {heading}
          </h2>
          <p className="text-xl text-gray-500">{supportingText}</p>
        </div>
        <ul
          className={`mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 ${gridColumns} lg:max-w-5xl`}
        >
          {teamMembers.map(({ name, photo, title }) => (
            <li key={name}>
              <div className="space-y-6">
                <Image
                  image={photo}
                  className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56 border-4 border-teal-500"
                  height={400}
                  width={400}
                />
                <div className="space-y-2">
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h3>{name}</h3>
                    <p className="text-teal-500">{title}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default TeamMembersSection
