import type { FC } from 'react'
import React from 'react'

const BlockRenderer: FC = ({ children }) => {
  return (
    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
      {children}
    </h1>
  )
}

export default BlockRenderer
