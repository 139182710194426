import Form from './Form'
import Image from 'components/Image'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const ContactSection: FC<Sanity.ContactSection> = ({ image, submit }) => {
  return (
    <div className="bg-gray-200">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="relative bg-white shadow-xl">
          <h2 className="sr-only">Contact us</h2>
          <div className="grid grid-cols-1 lg:grid-cols-3">
            <div className="relative overflow-hidden">
              <Image image={image} className="h-full w-full" />
            </div>
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <Form submit={submit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactSection
