import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const HeaderSection: FC<Sanity.HeaderSection & { isFirstSection: boolean }> = ({
  eyebrow,
  heading,
  isFirstSection,
  supportingText,
}) => {
  return (
    <div className="flex justify-center max-w-7xl mx-auto py-8 px-4 sm:py-24 sm:px-6 lg:px-4">
      <div className="text-center max-w-4xl">
        {eyebrow && !isFirstSection && (
          <h2 className="text-base font-semibold text-teal-500 tracking-wide uppercase">
            {eyebrow}
          </h2>
        )}
        {eyebrow && isFirstSection && (
          <p className="text-base font-semibold text-teal-500 tracking-wide uppercase">
            {eyebrow}
          </p>
        )}
        {isFirstSection ? (
          <h1 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {heading}
          </h1>
        ) : (
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {heading}
          </p>
        )}
        {supportingText && (
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            {supportingText}
          </p>
        )}
      </div>
    </div>
  )
}

export default HeaderSection
