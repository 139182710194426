import BlockRenderer from './block-renderer'
import HighlightRenderer from './highlight-renderer'
import BlockContent from '@sanity/block-content-to-react'
import type { FC } from 'react'
import React from 'react'
import sanityClientConfig from 'sanity/client-config'
import { Sanity } from 'types/sanity'

type Props = {
  blocks: Sanity.Block[]
}

const Heading: FC<Props> = ({ blocks }) => (
  <BlockContent
    blocks={blocks}
    serializers={{
      types: {
        block: BlockRenderer,
      },
      marks: {
        highlight: HighlightRenderer,
      },
    }}
    {...sanityClientConfig}
  />
)

export default Heading
