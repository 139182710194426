import Heading from './Heading'
import { Link } from 'gatsby'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const CtaSection: FC<Sanity.CtaSection> = ({ _rawHeading, cta }) => {
  return (
    <div className="bg-teal-600">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <Heading blocks={_rawHeading} />
        <Link
          to={`/${cta.page.slug.current}`}
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-teal-600 bg-white hover:bg-teal-50 sm:w-auto"
        >
          {cta.text}
        </Link>
      </div>
    </div>
  )
}

export default CtaSection
