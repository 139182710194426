import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const TestimonialsSection: FC<Sanity.TestimonialsSection> = ({
  heading,
  testimonials,
}) => {
  return (
    <>
      <div className="max-w-7xl mx-auto py-8 px-4 sm:py-24 sm:px-6 lg:px-4">
        <div className="text-center">
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {heading}
          </p>
        </div>
      </div>
      <div className="bg-teal-600">
        <div className="max-w-7xl mx-auto md:grid md:grid-cols-3 gap-x-8 px-6 lg:px-8">
          {testimonials.map(({ name, quote }) => (
            <div
              className="py-6 md:flex md:flex-col md:py-16 md:pl-0"
              key={name}
            >
              <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
                <div className="relative text-lg font-medium text-white md:flex-grow">
                  <svg
                    className="absolute top-0 left-0 transform -translate-x-4 -translate-y-6 h-8 w-8 text-teal-400"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="relative">{quote}</p>
                </div>
                <footer className="mt-8">
                  <div className="flex items-start">
                    <div className="text-base font-medium text-white">
                      {name}
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TestimonialsSection
