import Hours from 'components/Page/Footer/Hours'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const TextSection: FC<Sanity.TextSection> = ({ _rawContent }) => {
  return (
    <div className="h-auto relative text-xl text-gray-500 mb-8 max-w-3xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-4">
      <Hours blocks={_rawContent} />
    </div>
  )
}

export default TextSection
