import Heading from './Heading'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import DownloadPaperwork from 'components/DownloadPaperwork'
import Image from 'components/Image'
import Logo from 'components/Logo'
import { graphql, Link, useStaticQuery } from 'gatsby'
import type { FC } from 'react'
import React, { Fragment } from 'react'
import { Sanity } from 'types/sanity'

const HeroSection: FC<Sanity.HeroSection> = ({
  _rawHeading,
  supportingText,
  cta,
  downloadPaperwork,
  heroImage,
}) => {
  const { siteSettings } = useStaticQuery(graphql`
    query HeroSectionNav {
      siteSettings: sanitySiteSettings {
        companyName
        nav {
          slug {
            current
          }
          title
        }
      }
    }
  `)

  const nav: Array<Sanity.Page> = siteSettings.nav
  const lastNavItem = nav[nav.length - 1]

  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-3xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            {({ open }) => (
              <>
                <div className="relative pt-10 pl-4 pr-4 sm:pl-6 lg:pl-8">
                  <nav
                    className="relative flex items-center justify-between sm:h-36 lg:justify-start"
                    aria-label="Global"
                  >
                    <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                      <div className="flex items-center justify-between w-full md:w-auto">
                        <Link to="/">
                          <span className="sr-only">
                            {siteSettings.companyName}
                          </span>
                          <Logo className="h-28 w-auto sm:h-36" />
                        </Link>
                        <div className="-mr-2 flex items-center md:hidden">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                            <span className="sr-only">Open main menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8 font-medium last:text-teal-500">
                      {nav.map(({ slug, title }, index) => {
                        const isLast = index === nav.length - 1
                        return (
                          <Link
                            key={slug.current}
                            to={`/${slug.current}`}
                            className={`font-medium ${
                              isLast
                                ? 'text-teal-500 hover:text-teal-500'
                                : 'text-gray-500 hover:text-gray-900'
                            }`}
                          >
                            {title}
                          </Link>
                        )
                      })}
                    </div>
                  </nav>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Popover.Panel
                    focus
                    static
                    className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                  >
                    <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="px-5 pt-10 flex items-center justify-between">
                        <div>
                          <Logo className="h-36 w-auto" />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                            <span className="sr-only">Close main menu</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="px-2 pt-2 pb-3 space-y-1">
                        {nav.slice(0, -1).map(({ slug, title }) => {
                          return (
                            <Link
                              key={slug.current}
                              to={`/${slug.current}`}
                              className="block font-medium px-3 py-2 rounded-md text-base text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                            >
                              {title}
                            </Link>
                          )
                        })}
                      </div>
                      <Link
                        to={`/${lastNavItem.slug.current}`}
                        className="block font-medium w-full px-5 py-3 text-center text-teal-500 bg-gray-50 hover:bg-gray-100"
                      >
                        {lastNavItem.title}
                      </Link>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <Heading blocks={_rawHeading} />
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {supportingText}
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <Link
                    to={`/${cta.page.slug.current}`}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-500 hover:bg-teal-700 md:py-4 md:text-lg md:px-10"
                  >
                    {cta.text}
                  </Link>
                </div>
                {downloadPaperwork && downloadPaperwork.text && (
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <DownloadPaperwork
                      className="w-full flex items-center justify-center px-8 py-3 border border-teal-700 text-base font-medium rounded-md text-teal-700 bg-teal-50 hover:bg-teal-200 md:py-4 md:text-lg md:px-10"
                      text={'Download Paperwork'}
                    />
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <Image
          image={heroImage}
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        />
      </div>
    </div>
  )
}

export default HeroSection
