import FormSchema from './form-schema'
import type { FormikHelpers, FormikProps } from 'formik'
import { ErrorMessage, Field, Formik } from 'formik'
import { navigate } from 'gatsby'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

type Props = {
  submit: Sanity.ContactSection['submit']
}

type FormValues = {
  firstName: string
  lastName: string
  email: string
  phone: string
  subject: string
  message: string
}

const encode = (data: Record<PropertyKey, string | number>) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
}

const Form: FC<Props> = ({ submit }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: FormValues, formikBag: FormikHelpers<FormValues>) => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'contact', ...values }),
        })
          .then(() => {
            formikBag.resetForm()
            navigate(`/${submit.page.slug.current}`)
          })
          .catch(error => {
            console.error(error)
          })
      }}
      validationSchema={FormSchema}
    >
      {({
        dirty,
        handleSubmit,
        isSubmitting,
        isValid,
      }: FormikProps<FormValues>) => (
        <form
          name="contact"
          data-netlify={true}
          className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          onSubmit={handleSubmit}
        >
          <div>
            <label
              className="block text-sm font-medium text-gray-900"
              htmlFor="name"
            >
              First name
            </label>
            <div className="mt-1">
              <Field
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                name="firstName"
                required
                type="text"
              />
              <ErrorMessage name="firstName">
                {(msg: string) => (
                  <span className="text-red-500 mt-1 ml-1">{msg}</span>
                )}
              </ErrorMessage>
            </div>
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-900"
              htmlFor="last-name"
            >
              Last name
            </label>
            <div className="mt-1">
              <Field
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                name="lastName"
                required
                type="text"
              />
              <ErrorMessage name="lastName">
                {(msg: string) => (
                  <span className="text-red-500 mt-1 ml-1">{msg}</span>
                )}
              </ErrorMessage>
            </div>
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-900"
              htmlFor="email"
            >
              Email
            </label>
            <div className="mt-1">
              <Field
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                name="email"
                required
                type="email"
              />
              <ErrorMessage name="email">
                {(msg: string) => (
                  <span className="text-red-500 mt-1 ml-1">{msg}</span>
                )}
              </ErrorMessage>
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <label
                className="block text-sm font-medium text-gray-900"
                htmlFor="phone"
              >
                Phone
              </label>
              <span id="phone-optional" className="text-sm text-gray-500">
                Optional
              </span>
            </div>
            <div className="mt-1">
              <Field
                aria-describedby="phone-optional"
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                name="phone"
                type="text"
              />
              <ErrorMessage name="phone">
                {(msg: string) => (
                  <span className="text-red-500 mt-1 ml-1">{msg}</span>
                )}
              </ErrorMessage>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              className="block text-sm font-medium text-gray-900"
              htmlFor="subject"
            >
              Subject
            </label>
            <div className="mt-1">
              <Field
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
                name="subject"
                required
                type="text"
              />
              <ErrorMessage name="subject">
                {(msg: string) => (
                  <span className="text-red-500 mt-1 ml-1">{msg}</span>
                )}
              </ErrorMessage>
            </div>
          </div>
          <div className="sm:col-span-2">
            <div className="flex justify-between">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-900"
              >
                Message
              </label>
              <span id="message-max" className="text-sm text-gray-500">
                Max. 500 characters
              </span>
            </div>
            <div className="mt-1">
              <Field
                aria-describedby="message-max"
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-teal-500 focus:border-teal-500 border border-gray-300 rounded-md"
                component="textarea"
                name="message"
                required
                rows={4}
              />
              <ErrorMessage name="message">
                {(msg: string) => (
                  <span className="text-red-500 mt-1 ml-1">{msg}</span>
                )}
              </ErrorMessage>
            </div>
          </div>
          <div className="sm:col-span-2 sm:flex sm:justify-end">
            <button
              className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-500 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto"
              disabled={isSubmitting || !isValid || !dirty}
              type="submit"
            >
              {submit.text}
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default Form
