import DownloadPaperwork from 'components/DownloadPaperwork'
import Image from 'components/Image'
import BlockContent from 'components/Post/BlockContent'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

const CardsSection: FC<Sanity.CardsSection> = ({
  eyebrow,
  heading,
  supportingText,
  cards,
}) => {
  const sanityCards = cards as Sanity.Card[]

  return (
    <div className="bg-teal-600 mt-10">
      <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
        <div className="text-center">
          <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
            {eyebrow}
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
            {heading}
          </p>
          <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-300 sm:mt-5 sm:text-2xl">
            {supportingText}
          </p>
        </div>
      </div>

      <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
        <div className="relative z-0">
          <div className="absolute inset-0 h-5/6 bg-teal-600 lg:h-2/3" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative lg:grid lg:grid-cols-7">
              <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                  <div className="flex-1 flex flex-col">
                    <Image className="w-full" image={sanityCards[0].image} />
                    <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                      <h3 className="text-lg text-center leading-6 font-bold text-gray-900 mb-2">
                        {sanityCards[0].heading}
                      </h3>
                      <BlockContent
                        blocks={sanityCards[0]._rawSupportingText}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                <div className="h-full flex flex-col rounded-lg shadow-xl overflow-hidden border-2 border-teal-300">
                  <Image className="w-full" image={sanityCards[1].image} />
                  <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                    <h3 className="text-lg text-center leading-6 font-bold text-gray-900">
                      {sanityCards[1].heading}
                    </h3>
                    <BlockContent blocks={sanityCards[1]._rawSupportingText} />
                    {sanityCards[1].downloadPaperwork &&
                      sanityCards[1].downloadPaperwork.text && (
                        <div className="mt-10">
                          <div className="rounded-lg shadow-md">
                            <DownloadPaperwork
                              className="inline-flex items-center justify-center w-full text-center rounded-lg border border-transparent bg-teal-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-teal-700"
                              text={
                                sanityCards[1].downloadPaperwork?.text || ''
                              }
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                  <div className="flex-1 flex flex-col">
                    <Image className="w-full" image={sanityCards[2].image} />
                    <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                      <h3 className="text-lg text-center leading-6 font-bold text-gray-900 mb-2">
                        {sanityCards[2].heading}
                      </h3>
                      <BlockContent
                        blocks={sanityCards[2]._rawSupportingText}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardsSection
