import BlogSection from './BlogSection'
import CardsSection from './CardsSection'
import ContactSection from './ContactSection'
import CtaSection from './CtaSection'
import HeaderSection from './HeaderSection'
import ImageCarouselSection from './ImageCarouselSection'
import ListSection from './ListSection'
import MapSection from './MapSection'
import TeamMemberSection from './TeamMemberSection'
import TeamMembersSection from './TeamMembersSection'
import TestimonialsSection from './TestimonialsSection'
import TextSection from './TextSection'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

type Props = {
  sections: Sanity.PageSections
}

const Sections: FC<Props> = ({ sections }) => {
  return (
    <>
      {sections.map((section, index) => {
        const sectionType = section._type
        switch (sectionType) {
          case 'blogSection':
            return (
              <BlogSection
                key={section._key}
                {...(section as Sanity.BlogSection)}
              />
            )
          case 'cardsSection':
            return (
              <CardsSection
                key={section._key}
                {...(section as Sanity.CardsSection)}
              />
            )
          case 'contactSection':
            return (
              <ContactSection
                key={section._key}
                {...(section as Sanity.ContactSection)}
              />
            )
          case 'ctaSection':
            return (
              <CtaSection
                key={section._key}
                {...(section as Sanity.CtaSection)}
              />
            )
          case 'headerSection':
            return (
              <HeaderSection
                isFirstSection={index === 0}
                key={section._key}
                {...(section as Sanity.HeaderSection)}
              />
            )
          case 'imageCarouselSection':
            return (
              <ImageCarouselSection
                key={section._key}
                {...(section as Sanity.ImageCarouselSection)}
              />
            )
          case 'listSection':
            return (
              <ListSection
                key={section._key}
                {...(section as Sanity.ListSection)}
              />
            )
          case 'mapSection':
            return (
              <MapSection
                key={section._key}
                {...(section as Sanity.MapSection)}
              />
            )
          case 'teamMemberSection':
            return (
              <TeamMemberSection
                key={section._key}
                {...(section as Sanity.TeamMemberSection)}
              />
            )
          case 'teamMembersSection':
            return (
              <TeamMembersSection
                key={section._key}
                {...(section as Sanity.TeamMembersSection)}
              />
            )
          case 'testimonialsSection':
            return (
              <TestimonialsSection
                key={section._key}
                {...(section as Sanity.TestimonialsSection)}
              />
            )
          case 'textSection':
            return (
              <TextSection
                key={section._key}
                {...(section as Sanity.TextSection)}
              />
            )
        }
      })}
    </>
  )
}

export default Sections
